 
interface SiteIdentityType {
  address1: string;
  address2: string;
  address3: string;
  email: string;
  phone: string;
  id: number;
  title: string;
  url: string;
  pageName: string;
}

export const SiteIdentity: SiteIdentityType[] = [
  {
    id: 0,
    title: 'Molto SEO',
    phone: '+44 (0) 7441 397 020',
    email: 'team@moltoseo.co.uk',
    address1: '9 Moreland St',
    address2: 'London EC1V 8BE',
    address3: 'United Kingdom',
    url: 'https://moltoseo.co.uk',
    pageName: ''
  },
  {
    id: 1,
    title: 'Molto SEO',
    phone: '+44 (0) 7441 397 020',
    email: 'team@moltoseo.co.uk',
    address1: '29 Adelaide Crescent',
    address2: 'Brighton & Hove, BN3 2JH',
    address3: 'United Kingdom',
    url: 'https://moltoseo.co.uk',
    pageName: ''
  }
];

//home
export const HomeList = [
  {
    order: 0,
    id: 0,
    title: 'technical SEO agency',
    url: '',
    pageName: 'Home'
  },
  {
    id: 1,
    title: 'technical SEO London',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'technical SEO company',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'technical SEO UK',
    url: '',
    pageName: ''
  },
  {
    //repeated in seoAudit
    id: 4,
    title: 'technical SEO audit services',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'website SEO audit services',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'technical SEO consultants',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'technical SEO specialist',
    url: '',
    pageName: ''
  },
  {
    id: 8,
    title: 'technical SEO services',
    url: '',
    pageName: ''
  },
  {
    id: 9,
    important: false,
    title: 'technical SEO expert',
    url: '',
    pageName: ''
  }
];
//main page
export const AI = [
  {
    order: 1,
    id: 0,
    title: 'how AI can affect your digital marketing campaigns',
    url: 'blog',
    pageName: 'How AI can affect...'
  },
  {
    id: 1,
    title: 'AI SEO software',
    url: '',
    pageName: ''
  },
  { id: 2, title: 'AI SEO', url: '', pageName: '' },
  {
    id: 3,
    title: 'SEO AI',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'AI SEO tools',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'AI for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'AI SEO content generator',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'SEO AI tools',
    url: '',
    pageName: ''
  },
  {
    id: 8,
    title: 'SEO AI writer',
    url: '',
    pageName: ''
  },
  {
    id: 9,
    title: 'AI and SEO',
    url: '',
    pageName: ''
  },
  {
    id: 10,
    title: 'AI based SEO',
    url: '',
    pageName: ''
  },
  {
    id: 11,
    title: 'AI and SEO',
    url: '',
    pageName: ''
  },
  {
    id: 12,
    title: 'AI based SEO',
    url: '',
    pageName: ''
  },
  {
    id: 13,
    title: 'AI based SEO tools',
    url: '',
    pageName: ''
  },
  {
    id: 14,
    title: 'AI content and SEO',
    url: '',
    pageName: ''
  },
  {
    id: 15,
    title: 'AI content for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 16,
    title: 'AI content SEO',
    url: '',
    pageName: ''
  },
  {
    id: 17,
    title: 'AI for SEO content',
    url: '',
    pageName: ''
  },
  {
    id: 18,
    title: 'AI SEO content',
    url: '',
    pageName: ''
  },
  {
    id: 19,
    title: 'AI SEO content writing',
    url: '',
    pageName: ''
  },
  {
    id: 20,
    title: 'AI SEO copywriting',
    url: '',
    pageName: ''
  },
  {
    id: 21,
    title: 'AI do seu lado',
    url: '',
    pageName: ''
  },
  {
    id: 22,
    title: 'AI engineer salary at google',
    url: '',
    pageName: ''
  },
  {
    id: 23,
    title: 'AI for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 24,
    title: 'AI for SEO optimization',
    url: '',
    pageName: ''
  },
  {
    id: 25,
    title: 'AI generated SEO',
    url: '',
    pageName: ''
  },
  {
    id: 26,
    title: 'AI SEO agency',
    url: '',
    pageName: ''
  },
  {
    id: 27,
    title: 'AI SEO analyzer',
    url: '',
    pageName: ''
  },
  {
    id: 28,
    title: 'AI SEO art',
    url: '',
    pageName: ''
  },
  {
    id: 29,
    title: 'AI SEO article',
    url: '',
    pageName: ''
  },
  {
    //repeated in seoAudit
    id: 30,
    title: 'AI SEO audit',
    url: '',
    pageName: ''
  },
  {
    id: 31,
    title: 'AI SEO blog',
    url: '',
    pageName: ''
  },
  {
    id: 32,
    title: 'AI SEO blog writer',
    url: '',
    pageName: ''
  },
  {
    id: 33,
    title: 'AI SEO checker',
    url: '',
    pageName: ''
  },
  {
    id: 34,
    title: 'AI SEO ci pego',
    url: '',
    pageName: ''
  },
  {
    id: 35,
    title: 'AI SEO description generator',
    url: '',
    pageName: ''
  },
  {
    id: 36,
    title: 'AI SEO for etsy',
    url: '',
    pageName: ''
  },
  {
    id: 37,
    title: 'AI SEO free',
    url: '',
    pageName: ''
  },
  {
    id: 38,
    title: 'AI SEO generator',
    url: '',
    pageName: ''
  },
  {
    id: 39,
    title: 'AI SEO generator free',
    url: '',
    pageName: ''
  },
  {
    id: 40,
    title: 'AI SEO google',
    url: '',
    pageName: ''
  },
  {
    id: 41,
    title: 'AI SEO software',
    url: '',
    pageName: ''
  },
  {
    id: 42,
    title: 'AI SEO text generator free',
    url: '',
    pageName: ''
  },
  {
    id: 43,
    title: 'AI SEO writer',
    url: '',
    pageName: ''
  },
  {
    id: 44,
    title: 'AI seu eu te pego',
    url: '',
    pageName: ''
  },
  {
    id: 45,
    title: 'AI seu gostoso letra',
    url: '',
    pageName: ''
  },
  {
    id: 46,
    title: 'AI seu guarda',
    url: '',
    pageName: ''
  },
  {
    id: 47,
    title: 'AI tools for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 48,
    title: 'artificial intelligence and SEO',
    url: '',
    pageName: ''
  },
  {
    id: 49,
    title: 'best AI for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 50,
    title: 'best AI SEO software',
    url: '',
    pageName: ''
  },
  {
    id: 51,
    title: 'best AI SEO tool',
    url: '',
    pageName: ''
  },
  {
    id: 52,
    title: 'best AI SEO writer',
    url: '',
    pageName: ''
  },
  {
    id: 53,
    title: 'best AI writer for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 54,
    title: 'can AI do SEO',
    url: '',
    pageName: ''
  },
  {
    id: 55,
    title: 'copy AI SEO',
    url: '',
    pageName: ''
  },
  {
    id: 56,
    title: 'etsy SEO AI',
    url: '',
    pageName: ''
  },
  {
    id: 57,
    title: 'examples of AI in ecommerce',
    url: '',
    pageName: ''
  },
  {
    id: 58,
    title: 'free AI SEO tools',
    url: '',
    pageName: ''
  },
  {
    id: 59,
    title: 'free SEO AI writer',
    url: '',
    pageName: ''
  },
  {
    id: 60,
    title: 'google AI SEO',
    url: '',
    pageName: ''
  },
  {
    id: 61,
    title: 'how AI is used in ecommerce',
    url: '',
    pageName: ''
  },
  {
    id: 62,
    title: 'how does google rank SEO',
    url: '',
    pageName: ''
  },
  {
    id: 63,
    title: 'how to use AI for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 64,
    title: 'is AI content bad for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 65,
    title: 'is AI writing good for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 66,
    title: 'jasper AI and SEO',
    url: '',
    pageName: ''
  },
  {
    id: 67,
    title: 'jasper AI and surfer SEO',
    url: '',
    pageName: ''
  },
  {
    id: 68,
    title: 'jasper AI for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 69,
    title: 'jasper AI SEO',
    url: '',
    pageName: ''
  },
  {
    id: 70,
    title: 'open AI for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 71,
    title: 'SEO AI circles',
    url: '',
    pageName: ''
  },
  {
    id: 72,
    title: 'SEO AI copywriter',
    url: '',
    pageName: ''
  },
  {
    id: 73,
    title: 'SEO average position',
    url: '',
    pageName: ''
  },
  {
    id: 74,
    title: 'SEO ban AI',
    url: '',
    pageName: ''
  },
  {
    id: 75,
    title: 'SEO by AI',
    url: '',
    pageName: ''
  },
  {
    id: 76,
    title: 'using AI for SEO',
    url: '',
    pageName: ''
  },
  {
    id: 77,
    title: 'using AI in SEO',
    url: '',
    pageName: ''
  },
  {
    id: 78,
    title: 'which is irrelevant when it comes to AI in SEO',
    url: '',
    pageName: ''
  },
  {
    id: 79,
    title: 'will AI replace SEO',
    url: '',
    pageName: ''
  },
  {
    id: 80,
    title: 'will SEO be replaced by AI',
    url: '',
    pageName: ''
  },
  {
    id: 81,
    title: 'chatGPT',
    url: '',
    pageName: ''
  }
];

export const B2B = [
  {
    id: 0,
    title: 'B2B SEO consultant London',
    url: 'london',

    pageName: 'B2B SEO consultant London'
  },
  {
    id: 1,
    title: 'b2b SEO agency',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'local SEO B2B',
    url: '',
    pageName: ''
  }
];

//main page
export const BespokeWebsiteDesign = [
  {
    id: 0,
    title: 'bespoke website design',
    url: 'london',

    pageName: ''
  },
  {
    id: 1,
    title: 'bespoke website',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'cost of website design UK',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'website design prices UK',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'website designing near me',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'web services London',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'web development company London',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'web development company near me',
    url: '',
    pageName: ''
  },
  {
    id: 8,
    title: 'bespoke web development',
    url: '',
    pageName: ''
  }
];

//main page
export const CRO = [
  {
    id: 0,
    title: 'CRO agency London',
    url: 'london',

    pageName: 'CRO agency London'
  },
  {
    id: 1,
    title: 'CRO agency UK',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'conversion optimisation services',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'conversion rate optimisation services London',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'conversion rate optimisation agency',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'conversion optimisation agency',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'conversion rate optimisation company',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'conversion rate optimisation services',
    url: '',
    pageName: ''
  }
];

export const Dental = [
  {
    id: 0,
    title: 'dental SEO agency',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'SEO dental marketing',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'dental SEO services',
    url: '',
    pageName: ''
  }
];

//main page
export const Digital = [
  {
    id: 0,
    title: 'digital strategy agency',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'digital marketing near me',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'digital marketing agency near me',
    url: '',
    pageName: ''
  }
];

//NEW
//
export const DigitalMarketing = [
  {
    id: 0,
    title: 'digital marketing near me',
    url: 'london',

    pageName: ''
  },
  {
    id: 1,
    title: 'digital marketing companies near me',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'marketing agency near me',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'digital marketing consultant near me',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'digital marketing company near me',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'internet marketing consultants near me',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'digital marketing consultant London',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'digital marketing packages',
    url: '',
    pageName: ''
  },
  {
    id: 8,
    title: 'full service digital marketing agency',
    url: '',
    pageName: ''
  },
  {
    id: 9,
    title: 'marketing automation agency',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 10,
    title: 'marketing strategy agency',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 11,
    title: 'marketing company London',
    url: '',
    pageName: ''
  }
];
//main
export const HowMuchWebsiteCost = [
  {
    id: 0,
    title: 'average cost of website design for small business UK',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'cost of setting up a website UK',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'website cost UK',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'how much does a website cost per month UK',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'how much is a website UK',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'website cost calculator',
    url: '',
    pageName: ''
  }
];
//main page
export const EcommerceWebDesign = [
  {
    id: 0,
    title: 'e commerce website development London',
    url: 'london',

    pageName: ''
  },
  {
    id: 1,
    title: 'ecommerce web design agency',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'ecommerce web design London',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'ecommerce website design London',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'ecommerce website design agency',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'ecommerce web design company',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 6,
    title: 'ecommerce marketing agency',
    url: '',
    pageName: ''
  }
];

//main page
export const EcommerceSEO = [
  {
    id: 0,
    title: 'ecommerce SEO agency',
    url: '',
    pageName: 'ecommerce SEO agency'
  },
  {
    id: 1,
    title: 'ecommerce SEO specialist',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'ecommerce SEO expert',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'ecommerce SEO company',
    url: '',
    pageName: ''
  },
  {
    //repeated in seoAudit
    important: false,
    id: 4,
    title: 'ecommerce SEO audit',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'ecommerce SEO consultant',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'ecommerce SEO packages',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'SEO ecommerce agency',
    url: '',
    pageName: ''
  },
  {
    id: 8,
    title: 'SEO services for ecommerce websites',
    url: '',
    pageName: ''
  },
  {
    id: 9,
    title: 'SEO services for ecommerce',
    url: '',
    pageName: ''
  },
  {
    id: 10,
    title: 'magento SEO expert',
    url: '',
    pageName: ''
  },
  {
    id: 11,
    title: 'ecommerce SEO packages',
    url: '',
    pageName: ''
  }
];

//main page
export const GoogleAnalytics = [
  {
    id: 0,
    title: 'Google Analytics agency',
    url: 'london',

    pageName: 'Google Analytics Agency London'
  },
  {
    id: 2,
    title: 'Google analytics consultant',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'web analytics consulting',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'web analytics agency',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'Google ads agency London',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'Google ads agency UK',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'analytics agencies',
    url: '',
    pageName: ''
  }
];
export const GooglePenalty = [
  {
    id: 0,
    title: 'Google penalty removal',
    url: '',
    pageName: ''
  }
];
export const GoogleShopping = [
  {
    id: 0,
    title: 'Google shopping agency',
    url: '',
    pageName: ''
  }
];

//main page
export const HostingSupport = [
  {
    id: 0,
    title: 'website support',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'website maintenance',
    url: '',
    pageName: ''
  }
];

export const InternationalSEOArrays = [
  {
    order: 12,
    item: 0,
    title: 'hotel SEO company', //JJW Hotels

    category: 'multilingual seo',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark',
    url: 'london/seo-design-portfolio',
    pageName: 'JJW Hotels',
    keywords: [
      {
        id: 0,
        title: 'hotel SEO company',
        url: 'london/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'multilingual SEO services',
        url: '',
        pageName: ''
      }
    ]
  },
  {
    item: 1,
    title: 'international SEO agency London', //MBI International
    category: 'on-page seo, linking strategy',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark',

    url: 'london/seo-design-portfolio',
    pageName: '',
    keywords: [
      {
        id: 0,
        title: 'international SEO agency London',
        url: 'london/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'international SEO agency',
        url: '',
        pageName: ''
      },
      {
        id: 2,
        title: 'international SEO consultant',
        url: '',
        pageName: ''
      },
      {
        id: 3,
        title: 'international SEO expert',
        url: '',
        pageName: ''
      },
      {
        id: 4,
        title: 'international SEO services',
        url: '',
        pageName: ''
      },
      {
        id: 5,
        title: 'international SEO company',
        url: '',
        pageName: ''
      }
    ]
  }
];
export const LawFirm = [
  {
    id: 0,
    title: 'law firm SEO services',
    url: '',
    pageName: ''
  }
];

export const LocalSEOArrays = [
  {
    order: 2,
    item: 0,
    title: 'brighton SEO agency', //
    category: 'seo, ppc, social, web design',
    displayText: 'd-none',
    displayTextColor: 'text-white',

    url: 'brighton/seo-design-portfolio',
    pageName: 'The Brighton Removals Company',
    keywords: [
      {
        id: 0,
        title: 'brighton SEO agency',
        url: 'brighton/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'SEO company in Brighton',
        url: '',
        pageName: ''
      },
      {
        id: 2,
        title: 'SEO agency Brighton',
        url: '',
        pageName: ''
      },
      {
        id: 3,
        title: 'SEO Brighton',
        url: '',
        pageName: ''
      }
    ]
  },
  // {
  //   order: 3,
  //   item: 1,
  //   title: 'web design for tradesmen', //

  //   category: 'seo, web design',
  //   displayText: 'd-none',
  //   displayTextColor: 'text-white',
  //   url: 'brighton/seo-design-portfolio',
  //   pageName: 'The Tree Company Sussex',
  //   keywords: [
  //     {
  //       id: 0,
  //       title: 'web design for tradesmen',
  //       url: 'brighton/seo-design-portfolio',
  //       pageName: ''
  //     },
  //     {
  //       id: 1,
  //       title: 'websites for tradesmen',
  //       url: '',
  //       pageName: ''
  //     },
  //     {
  //       id: 2,
  //       title: 'websites for plumbers',
  //       url: '',
  //       pageName: ''
  //     }
  //   ]
  // },
  {
    order: 3,
    item: 1,
    title: 'SEO for estate agents', //

    category: 'seo, web design',
    displayText: 'd-none',
    displayTextColor: 'text-white',
    url: 'london/seo-design-portfolio',
    pageName: 'SEO for estate agents',
    keywords: [
      {
        id: 0,
        title: 'SEO for estate agents',
        url: 'london/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'SEO for estate agents',
        url: '',
        pageName: ''
      }, 
    ]
  },
  {
    order: 4,
    item: 2,
    title: 'professional UK SEO', //
    category: 'seo, bespoke website design',
    displayText: 'd-none',
    displayTextColor: 'text-dark',
    url: 'brighton/seo-design-portfolio',
    pageName: 'Gold Bear Removals Brighton',
    keywords: [
      {
        id: 0,
        title: 'professional UK SEO',
        url: 'brighton/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'national SEO services',
        url: '',
        pageName: ''
      },
      {
        important: false,
        id: 2,
        title: 'professional UK SEO Brighton',
        url: '',
        pageName: ''
      }
    ]
  },
  {
    order: 5,
    item: 3,
    title: 'digital marketing agency Brighton', //
    category: 'seo, ecommerce',
    displayText: 'd-inline-block',
    displayTextColor: 'text-white',
    url: 'brighton/seo-design-portfolio',

    pageName: 'Brighton Boxes',
    keywords: [
      {
        id: 0,
        title: 'digital marketing agency Brighton',
        url: 'brighton/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'digital agency Brighton',
        url: '',
        pageName: ''
      },
      {
        id: 2,
        title: 'digital marketing Brighton',
        url: '',
        pageName: ''
      }
    ]
  },
  {
    order: 6,
    item: 4,
    title: 'SEO company', //
    category: 'seo, ppc, ecommerce design',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark',
    pageName: 'BoxBoys Packaging',
    keywords: [
      {
        id: 0,
        title: 'SEO company',
        url: 'london/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'SEO companies near me',
        url: '',
        pageName: ''
      },
      {
        id: 2,
        title: 'SEO company near me',
        url: '',
        pageName: ''
      },
      {
        id: 3,
        title: 'best SEO company London',
        url: '',
        pageName: ''
      },
      {
        id: 4,
        title: 'SEO company services',
        url: '',
        pageName: ''
      }
    ]
  },

  {
    order: 7,
    item: 5,
    title: 'WordPress SEO agency',

    category: 'seo, ppc, social, wordpress',
    displayText: 'd-inline-block',
    displayTextColor: 'text-white',
    url: 'london/seo-design-portfolio',
    pageName: 'London Hair by Jackie',
    keywords: [
      {
        id: 0,
        title: 'WordPress SEO agency',
        url: 'london/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'WordPress SEO consultant',
        url: '',
        pageName: ''
      },
      {
        id: 2,
        title: 'WordPress SEO company',
        url: '',
        pageName: ''
      },
      {
        id: 3,
        title: 'WordPress SEO expert',
        url: '',
        pageName: ''
      },
      {
        id: 4,
        title: 'WordPress SEO services',
        url: '',
        pageName: ''
      }
    ]
  },
  {
    order: 8,
    item: 6,
    title: 'digital marketing for health', //
    category: 'digital marketing, health',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark',
    url: 'london/seo-design-portfolio',
    pageName: 'Charlotte Watts',
    keywords: [
      {
        id: 0,
        title: 'digital marketing for health and wellness',
        url: 'london/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'digital health marketing agency',
        url: '',
        pageName: ''
      },
      {
        id: 2,
        title: 'healthcare seo',
        url: '',
        pageName: ''
      },
      {
        id: 3,
        title: 'healthcare website design',
        url: '',
        pageName: ''
      },
      {
        id: 4,
        title: 'healthcare marketing agency',
        url: '',
        pageName: ''
      },
      {
        id: 5,
        title: 'medical seo',
        url: '',
        pageName: ''
      }
    ]
  },
  {
    order: 9,
    item: 7,
    title: 'affordable SEO London',

    category: '',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark',
    url: 'london/seo-design-portfolio',
    pageName: 'Smooth Mover Removals',
    keywords: [
      {
        id: 0,
        title: 'affordable SEO London',
        url: 'london/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'affordable SEO services London',
        url: '',
        pageName: ''
      }
    ]
  },
  {
    order: 10,
    item: 8,
    title: 'web design West Sussex',

    category: 'wordpress, web services',
    displayText: 'd-none',
    displayTextColor: 'text-white',
    url: 'brighton/seo-design-portfolio',
    pageName: 'Miguel Madeira',
    keywords: [
      {
        id: 0,
        title: 'web design West Sussex',
        url: 'brighton/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'web design Sussex',
        url: '',
        pageName: ''
      },
      {
        id: 2,
        title: 'website design Sussex',
        url: '',
        pageName: ''
      }
    ]
  },
  {
    order: 11,
    item: 9,
    title: 'small business marketing consultant',

    category: 'woocommerce, marketing',
    displayText: 'd-inline-block',
    displayTextColor: 'text-white',
    url: 'london/seo-design-portfolio',
    pageName: 'The Bodysurf School',
    keywords: [
      {
        id: 0,
        title: 'small business marketing consultant',
        url: 'london/seo-design-portfolio',
        pageName: ''
      },
      {
        id: 1,
        title: 'web design services for small business',
        url: '',
        pageName: ''
      }
    ]
  }
];

export const localSEOPackages = [
  {
    id: 0,
    title: 'local SEO packages',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'local SEO services UK',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'local SEO services near me',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 3,
    title: 'local SEO optimisation services',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 4,
    title: 'local SEO consultant',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 5,
    title: 'local SEO specialist',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 6,
    title: 'local SEO service',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 7,
    title: 'local SEO agency',
    url: '',
    pageName: ''
  }
];

// locations - don't forget to put all London links in folder "London"
// maybe rearrange some of the london keywords into a london specific folder
export const London = [
  {
    id: 1,
    title: 'web design and SEO London',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'web design North London',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'website SEO London',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'responsive web design London',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'website design cost UK',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'website design UK cost',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'tailored web design',
    url: '',
    pageName: ''
  },
  {
    id: 8,
    title: 'London SEO',
    url: '',
    pageName: ''
  }
];

export const LondonTowns = [
  {
    id: 0,
    title: 'kensington SEO company',
    url: 'london',

    pageName: ''
  },
  {
    id: 1,
    title: 'Mayfair SEO company',
    url: 'london',

    pageName: ''
  },
  {
    id: 2,
    title: 'Mayfair marketing company',
    url: 'london',

    pageName: ''
  },
  {
    id: 5,
    title: 'web design Richmond',
    url: 'london',

    pageName: ''
  }
];

//main
export const PaidMedia = [
  {
    id: 0,
    title: 'paid media',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'paid search agency services',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'paid search marketing services',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'paid search management',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'paid search services',
    url: '',
    pageName: ''
  }
];

//main
export const PPC = [
  {
    id: 0,
    title: 'SEO PPC services',
    url: 'london',
    pageName: ''
  },
  {
    id: 1,
    title: 'PPC management agency',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'PPC consultant London',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'Google ads agency London UK',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'Adwords management services',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 5,
    title: 'Adwords agency',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 6,
    title: 'google adwords agency',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 7,
    title: 'PPC agency UK',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 8,
    title: 'PPC advertising agency',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 9,
    title: 'PPC management London',
    url: '',
    pageName: ''
  }
];

const searchEngineConsultantsLondon = [
  {
    id: 0,
    title: 'search engine consultants',
    url: 'london',
    pageName: ''
  },
  {
    id: 1,
    title: 'search engine marketing London',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'search engine marketing UK',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'search engine optimisation agency',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'search engine optimisation London',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'search engine optimization company London',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'search engine optimization UK',
    url: '',
    pageName: ''
  }
];

export const SEOAccountants = [
  {
    id: 0,
    title: 'SEO for accountants',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'websites for accountants',
    url: '',
    pageName: ''
  }
];

//main
export const SEOAgency = [
  {
    id: 0,
    title: 'SEO agency near me',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'SEO agencies near me',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'SEO agency',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'SEO agency near me',
    url: '',
    pageName: ''
  }
];

//main
export const SEOAudit = [
  {
    id: 0,
    title: 'SEO audit London',
    url: 'london',
    pageName: 'SEO audit'
  },
  {
    id: 1,
    title: 'SEO audit services',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'SEO audit service',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'ecommerce SEO audit',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'AI SEO audit',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'technical SEO audit services',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'why SEO audit is important',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'SEO tech audit',
    url: '',
    pageName: ''
  },
  {
    id: 8,
    title: 'SEO audit checklist',
    url: '',
    pageName: ''
  },
  {
    id: 9,
    title: 'SEO audit template',
    url: '',
    pageName: ''
  },
  {
    id: 10,
    title: 'SEO audit agency',
    url: '',
    pageName: ''
  },
  {
    id: 11,
    title: 'SEO audit for free',
    url: '',
    pageName: ''
  },
  {
    id: 12,
    title: 'SEO audit cost',
    url: '',
    pageName: ''
  },
  {
    id: 13,
    title: 'how to do SEO audit of a website',
    url: '',
    pageName: ''
  },
  {
    id: 14,
    title: 'SEO audit example',
    url: '',
    pageName: ''
  },
  {
    id: 15,
    title: 'SEO audit meaning',
    url: '',
    pageName: ''
  },
  {
    id: 16,
    title: 'SEO audit and reporting tool',
    url: '',
    pageName: ''
  },
  {
    id: 17,
    title: 'SEO audit tools are dash and dash',
    url: '',
    pageName: ''
  },
  {
    id: 18,
    title: 'SEO audit tools list',
    url: '',
    pageName: ''
  }
];

//main
export const SEOConsultancy = [
  {
    id: 0,
    title: 'SEO London consulting',
    url: 'london',
    pageName: ''
  },
  {
    id: 0,
    title: 'SEO consultancy services',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'SEO consultancy',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'SEO consultant services',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'SEO consulting services',
    url: '',
    pageName: ''
  }
];

//main
export const SEOCopywriting = [
  {
    id: 0,
    title: 'SEO copywriting services',
    url: 'london',
    pageName: ''
  },
  {
    id: 1,
    title: 'content creation agency',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'content creation services',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'copywriting agency',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'SEO copywriting services UK',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'copywriting agency London',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'content agency London',
    url: '',
    pageName: ''
  },
  {
    id: 7,
    title: 'content strategy agency',
    url: '',
    pageName: ''
  },
  {
    id: 8,
    title: 'content marketing agency UK',
    url: '',
    pageName: ''
  },
  {
    id: 9,
    title: 'creative copywriter',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 10,
    title: 'digital content marketing agency',
    url: '',
    pageName: ''
  },
  {
    id: 11,
    title: 'copywriting agency UK',
    url: '',
    pageName: ''
  }
];
export const SEOEstate = [
  {
    id: 0,
    title: 'SEO for estate agents',
    url: '',
    pageName: ''
  }
];
export const SEOFinance = [
  {
    id: 0,
    title: 'SEO finance',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'SEO fintech marketing agency',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'fintech marketing agency',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'digital marketing for financial services',
    url: '',
    pageName: ''
  }
];
//main
export const SEOMigration = [
  {
    id: 0,
    title: 'SEO migration services',
    url: '',
    pageName: ''
  }
];

export const SEOnPage = [
  {
    id: 0,
    title: 'on page SEO services',
    url: '',
    pageName: ''
  }
];

//main
export const SEOPackages = [
  {
    id: 0,
    title: 'SEO packages UK',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'SEO management services',
    url: '',
    pageName: ''
  }
];

//main
export const SEOPricing = [
  {
    id: 0,
    title: 'SEO pricing UK',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'SEO costs UK',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'SEO costs UK',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'How much does SEO cost UK',
    url: '',
    pageName: ''
  }
];

export const SEOQuestions = [
  {
    id: 0,
    title: 'SEO with ai',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'SEO who to pronounce'
  },
  {
    id: 2,
    title: 'SEO to increase traffic'
  },
  {
    id: 3,
    title: 'SEO to increase website traffic'
  },
  {
    id: 4,
    title: 'who is SEO in guk sister'
  },
  {
    id: 5,
    title: 'who is SEO gyeong alchemy of souls'
  },
  {
    id: 6,
    title: 'who invented seo'
  },
  {
    id: 7,
    title: 'will SEO yul die'
  },
  {
    id: 8,
    title: 'will SEO yul die in alchemy of souls'
  },
  {
    id: 9,
    title: 'will SEO exist in 5 years'
  },
  {
    id: 10,
    title: 'will SEO die'
  },
  {
    id: 11,
    title: 'will SEO be replaced by ai'
  },
  {
    id: 12,
    title: 'will SEO be automated'
  },
  {
    id: 13,
    title: 'will SEO exist in 10 years'
  },
  {
    id: 14,
    title: 'will SEO yul survive'
  },
  {
    id: 15,
    title: 'where SEO is used'
  },
  {
    id: 16,
    title: 'SEO where to start'
  },
  {
    id: 17,
    title: 'SEO where to put keywords'
  },
  {
    id: 18,
    title: 'SEO where to get backlinks'
  },
  {
    id: 19,
    title: 'where is SEO soojin now'
  },
  {
    id: 20,
    title: 'where is SEO kang joon now'
  },
  {
    id: 21,
    title: 'where is SEO ye ji now'
  },
  {
    id: 22,
    title: 'where is SEO woo now'
  },
  {
    id: 23,
    title: 'can SEO make you rich'
  },
  {
    id: 24,
    title: 'can SEO be automated'
  },
  {
    id: 25,
    title: 'can SEO be done without website'
  },
  {
    id: 26,
    title: 'can SEO help your business'
  },
  {
    id: 27,
    title: 'can SEO in guk speak english'
  },
  {
    id: 28,
    title: 'can SEO keywords be phrases'
  },
  {
    id: 29,
    title: 'can SEO work from home'
  },
  {
    id: 30,
    title: 'can SEO be free'
  },
  {
    id: 31,
    title: 'which SEO techniques should be avoided'
  },
  {
    id: 32,
    title: 'which SEO tool is best'
  },
  {
    id: 33,
    title: 'which SEO plugin is best for wordpress'
  },
  {
    id: 34,
    title: 'which SEO metrics are important to track'
  },
  {
    id: 35,
    title: 'which SEO include duplicate content'
  },
  {
    id: 36,
    title: 'which SEO course is best'
  },
  {
    id: 37,
    title: 'which SEO techniques are popular'
  },
  {
    id: 38,
    title: 'which SEO should be avoided'
  },
  {
    id: 39,
    title: 'what SEO means'
  },
  {
    id: 40,
    title: 'what SEO stands for'
  },
  {
    id: 41,
    title: 'what SEO principles are you familiar with'
  },
  {
    id: 42,
    title: 'what SEO specialist do'
  },
  {
    id: 43,
    title: 'what SEO marketing'
  },
  {
    id: 44,
    title: 'what SEO experts do'
  },
  {
    id: 45,
    title: 'what SEO keywords to use'
  },
  {
    id: 46,
    title: 'what SEO is'
  },
  {
    id: 47,
    title: 'why SEO is important'
  },
  {
    id: 48,
    title: 'why SEO is so important'
  },
  {
    id: 49,
    title: 'why SEO is important for small business'
  },
  {
    id: 50,
    title: 'why SEO is important for your website'
  },
  //repeated in seoAudit
  {
    id: 51,
    title: 'why SEO audit is important'
  },
  {
    id: 52,
    title: 'why SEO is better than ppc'
  },
  {
    id: 53,
    title: 'why SEO services are important'
  },
  {
    id: 54,
    title: 'why SEO is used'
  },
  {
    id: 55,
    title: 'when SEO started'
  },
  {
    id: 56,
    title: 'when SEO finally updates'
  },
  {
    id: 57,
    title: 'SEO when changing domain name'
  },
  {
    id: 58,
    title: 'SEO when building a website'
  },
  {
    id: 59,
    title: 'SEO when to use nofollow'
  },
  {
    id: 60,
    title: 'when did SEO start'
  },
  {
    id: 61,
    title: 'when did SEO taiji debut'
  },
  {
    id: 62,
    title: 'when did SEO become a thing'
  },
  {
    id: 63,
    title: 'how SEO works'
  },
  {
    id: 64,
    title: 'how SEO helps your business'
  },
  {
    id: 65,
    title: 'how SEO works for business'
  },
  {
    id: 66,
    title: 'how SEO and ppc work together'
  },
  {
    id: 67,
    title: 'how SEO works step by step'
  },
  {
    id: 68,
    title: 'how SEO works in digital marketing'
  },
  {
    id: 69,
    title: 'how SEO helps'
  },
  {
    id: 70,
    title: 'how SEO works for websites'
  },
  {
    id: 71,
    title: 'are SEO services worth it'
  },
  {
    id: 72,
    title: 'are SEO jobs in demand'
  },
  {
    id: 73,
    title: 'are SEO courses worth it'
  },
  {
    id: 74,
    title: 'are SEO tools worth it'
  },
  {
    id: 75,
    title: 'are SEO keywords case sensitive'
  },
  {
    id: 76,
    title: 'are SEO services worth it reddit'
  },
  {
    id: 77,
    title: 'are SEO free'
  },
  {
    id: 78,
    title: 'are SEO experts in deman'
  }
];

export const SEOService = [
  {
    id: 0,
    title: 'SEO service near me',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'SEO services near me',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'SEO specialists',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'SEO strategist',
    url: '',
    pageName: ''
  }
];

export const SEOShopify = [
  {
    id: 0,
    title: 'Shopify SEO services',
    url: 'london',
    pageName: ''
  },
  {
    id: 1,
    title: 'Shopify SEO consultant',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 2,
    title: 'Shopify agency London',
    url: '',
    pageName: ''
  }
];

export const SEOWhiteLabel = [
  {
    id: 0,
    title: 'white label local SEO',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'white label SEO UK',
    url: '',
    pageName: ''
  }
];
//main
export const Social = [
  {
    id: 0,
    title: 'paid social agency ',
    url: 'london',
    pageName: ''
  },
  {
    id: 1,
    title: 'Facebook ads agency UK',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'paid social media agency',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'TikTok Now BeReal',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'TikTok marketing agency',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 5,
    title: 'social media marketing London',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 6,
    title: 'social media marketing UK',
    url: '',
    pageName: ''
  },
  {
    important: false,
    id: 7,
    title: 'social media consultancy',
    url: '',
    pageName: ''
  }
];
//main
export const WoocommerceWebDesign = [
  {
    id: 0,
    title: 'Woocommerce web design',
    url: '',
    pageName: ''
  },
  {
    id: 1,
    title: 'Woocommerce agency',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'Woocommerce marketing agency',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'Woocommerce marketing agency',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'Woocommerce web design agency',
    url: '',
    pageName: ''
  }
];
//main - is this London?
export const WordPressDesign = [
  {
    id: 0,
    title: 'WordPress website cost UK',
    url: 'london',
    pageName: ''
  },
  {
    id: 1,
    title: 'bespoke WordPress website',
    url: '',
    pageName: ''
  },
  {
    id: 2,
    title: 'WordPress development company in London',
    url: '',
    pageName: ''
  },
  {
    id: 3,
    title: 'WordPress developer London',
    url: '',
    pageName: ''
  },
  {
    id: 4,
    title: 'WordPress development agency',
    url: '',
    pageName: ''
  },
  {
    id: 5,
    title: 'WordPress website design London',
    url: '',
    pageName: ''
  },
  {
    id: 6,
    title: 'WordPress web design London',
    url: '',
    pageName: ''
  }
];

export const MegaList = [
  HomeList,
  AI,
  B2B,
  BespokeWebsiteDesign,
  CRO,
  Dental,
  Digital,
  DigitalMarketing,
  HowMuchWebsiteCost,
  EcommerceWebDesign,
  EcommerceSEO,
  GoogleAnalytics,
  GooglePenalty,
  GoogleShopping,
  HostingSupport,
  InternationalSEOArrays,
  LawFirm,
  LocalSEOArrays,
  localSEOPackages,
  London,
  LondonTowns,
  PaidMedia,
  PPC,
  SEOAccountants,
  SEOAgency,
  SEOAudit,
  SEOConsultancy,
  SEOCopywriting,
  SEOEstate,
  SEOFinance,
  SEOMigration,
  SEOnPage,
  SEOPackages,
  SEOPricing,
  SEOQuestions,
  SEOService,
  SEOShopify,
  SEOWhiteLabel,
  Social,
  WoocommerceWebDesign,
  WordPressDesign,
  SiteIdentity
];

const Mega = [...Object.values(MegaList).flat()];
export default Mega;
